<template>
  <section>
    <LightBox
      v-if="medias.length > 0"
      :media="medias"
      :startAt="index"
      :showLightBox="visible"
      ref="ligthbox"
    ></LightBox>

    <div >
      <ul class="threadMessage">
        <li>
          <img :src="thread.profileImage" alt=""/>
        </li>
        <li>
          
          <span>{{ thread.userName }} : </span>
          <p v-if="thread.reason !== 'undefined'">
            {{ thread.reason }}
          </p>
          <div class="files" v-if="medias.length > 0">
            <div
              class="post"
              v-for="(n, i) in medias"
              :key="i + 'media'"
              @click="showImages(i)"
            >
              <img :src="n.thumb" alt="" v-if="n.type == 'image'" />

              <video width="320" height="240" controls v-if="n.type == 'video'">
                <source :src="n.thumb" type="video/mp4" />
                <source :src="n.thumb" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
              <v-icon v-if="n.approveStatus"> mdi-check </v-icon>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import LightBox from "vue-image-lightbox";
import { mapActions } from "vuex";
export default {
  name: "GigThread",
  data: () => ({
    medias: [],
    index: 0,
    images: [],
    videos: [],
    visible: false,
    submitLoading: false,
  }),
  props: ["thread"],
  components: {
    LightBox,
  },
  methods: {
    ...mapActions(["postGigContentThread"]),
    showImages(index) {
      this.index = index;
      this.visible = true;
      this.$refs.ligthbox.showImage(index);
    },
    async uploadContent() {
      this.submitLoading = true;
      this.images = [];
      this.videos = [];
      let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
      fileBundles.forEach((ele) => {
        if (ele.type.split("/")[0] == "image") {
          this.images.push(ele);
        }

        if (ele.type.split("/")[0] == "video") {
          this.videos.push(ele);
        }
      });
      let formData = new FormData();
      if (this.images != undefined) {
        this.images.forEach((ele) => {
          formData.append("images", ele);
        });
      }

      if (this.videos != undefined) {
        this.videos.forEach((ele) => {
          formData.append("videos", ele);
        });
      }
      formData.append("id", this.thread._id);
      await this.postGigContentThread(formData);
      this.submitLoading = false;
      this.$emit("submittedThread", true);
    },
  },
  created() {
    if (this.thread.images.length > 0) {
      this.thread.images.forEach((ele) => {
        this.medias.push({
          type: "image",
          thumb: ele.fileLocation,
          src: ele.fileLocation,
          srcset: ele.fileLocation,
          approveStatus: ele.gigApproveStatus,
        });
      });
    }

    if (this.thread.videos.length > 0) {
      this.thread.videos.forEach((ele) => {
        this.medias.push({
          type: "video",
          thumb: ele.fileLocation,
          src: ele.fileLocation,
          srcset: ele.fileLocation,
          approveStatus: ele.gigApproveStatus,
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .post {
    margin: 3px;
    width: 23%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    border: 1px solid #d8d8d8;
    img {
      padding: 10px 0;
      width: 100%;
      object-fit: cover;
    }
    .v-icon {
      left: 10px;
      top: 10px;
      padding: 5px;
      border-radius: 50%;
      position: absolute;
      height: 20px;
      width: 20px;
      font-size: 12px;
      background: rgb(1, 195, 1);
      color: #fff;
    }
  }
}
.threadMessage {
  padding-left: 0;
  display: flex;
  flex-flow: row;

  box-sizing: border-box;
  align-items: flex-start;
  li {
    &:first-of-type {
      max-width: 50px;
      height: 50px;
      border: 1px solid #000;
      border-radius: 50%;
      overflow: hidden;
      width: 20%;
      img {
        width: 100%;
        height: 100%;
        padding: 0;
        object-fit: cover;
        object-position: center;
      }
    }
    margin: 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    &:last-of-type {
      margin-left: 15px;
      width: 80%;
      span {
        font-weight: 500;
      }
    }
  }
}
</style>